import React from 'react';
import Loader from "./../layout/Loader";
import ClassNames from "classnames";
import S from "string";
import { Link } from 'react-router-dom';
import NodeTeaser from "./NodeTeaser";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'


class NewsTeaser extends NodeTeaser{


        render = () => {

            const teaserClasses = ClassNames({
                'teaser news': true,
                ['bg-' + S(this.props.bgColor).chompRight('_color').s] : true,
                ['hv-bg-' + S(this.props.foreColor).chompRight('_color').s] : true,
                ['text-' + S(this.props.foreColor).chompRight('_color').s] : true,
                ['hv-text-' + S(this.props.bgColor).chompRight('_color').s] : true,
            });

            const textClasses = ClassNames({
                'half':true
            })

            return (
            <Loader loaded={this.state.loaded}>
                <Link className="position-relative d-block" to={this.props.path?this.props.path.alias:'node/'+this.props.nid}>
                    <article className={teaserClasses}>
                        <div className="half">
                            {this.props.image &&
                                <div className="inner">
                                    {this._renderImage()}
                                </div>
                            }
                        </div>
                        <div className={textClasses}>
                            <div className="inner info p-3 d-flex align-items-start justify-content-between w-100">
                                <div className="d-flex flex-column h-100 justify-content-between">
                                    <h2 className="font-size-base m-0 p-0 w-100">
                                        {this.props.title}
                                    </h2>
                                    {this.props.attributes.field_news_date}
                                </div>
                                <div className="d-flex flex-column h-100 justify-content-between text-right align-items-end">
                                    <FontAwesomeIcon icon={['far', 'arrow-up']} className="ml-2 fa-arrow-up animate-top d-inline-block"/>
                                    news
                                </div>
                            </div>
                        </div>
                    </article>
                </Link>
            </Loader>
            )
        }


}

export default NewsTeaser