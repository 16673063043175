import ClassNames from 'classnames';
import m from 'moment';
import React, { Component } from 'react';
import Moment from "react-moment";
import S from 'string';

class PageHeader extends Component {


    constructor(props) {

        super(props);
    }

    render = () => {


        let metaImageClasses = ClassNames({
            'square': true,
            'position-relative': true
        })

        let metaTextClasses = ClassNames({
        })

        let imageStyle = 'large';

        switch (this.props.type) {
            case 'news':

                metaImageClasses = ClassNames({
                    'half': true,
                    'position-relative': true
                })

                metaTextClasses = ClassNames({
                    'quarter': true,
                    'position-relative': true,
                    ['bg-' + S(this.props.meta.field_background_color).chompRight('_color').s]: true,
                    ['text-' + S(this.props.meta.field_text_color).chompRight('_color').s]: true
                })
                imageStyle = 'large_landscape'
                break;
            case 'event':

                metaImageClasses = ClassNames({
                    'half': true,
                    'position-relative': true,
                    'order-2': true,
                    'shape': true,
                    ['bg-' + S(this.props.meta.field_background_color).chompRight('_color').s]: true,
                    ['svg-' + S(this.props.meta.field_text_color).chompRight('_color').s]: true,
                })

                metaTextClasses = ClassNames({
                    'half': true,
                    'position-relative': true,
                    ['bg-' + S(this.props.meta.field_background_color).chompRight('_color').s]: true,
                    ['text-' + S(this.props.meta.field_text_color).chompRight('_color').s]: true,
                    'order-1': true
                })
                imageStyle = 'large_landscape'
                break;
            default:
                break;

        }

        return (
            <header className="page-header bg-secondary mb-5">

                <div className="container">
                    <div className="row justify-content-center">
                        <div className={this.props.image || this.props.meta.field_shape ? 'col-md-6 col-lg-7 py-5' : 'col-lg-12 py-5'}>
                            <div className="row justify-content-center">
                                <div className={this.props.image ? 'col-lg-10' : 'col-lg-8'}>
                                    <h1 className="page-title text-primary text-uppercase">{this.props.pageTitle}</h1>

                                    {this.props.meta.field_sub_title &&
                                        <h2 className="text-white">{this.props.meta.field_sub_title}</h2>
                                    }
                                    {this.props.content &&
                                        <div className="intro text-muted"
                                            dangerouslySetInnerHTML={{ __html: this.props.content }}></div>
                                    }
                                </div>
                            </div>
                        </div>
                        {(this.props.image || this.props.meta.field_shape) &&
                            <div className="col-md-6 col-lg-5 d-flex flex-column">
                                <div className={metaImageClasses}>
                                    <div className="inner">
                                        {this.props.image && this.props.image.meta.imageDerivatives &&
                                            <img
                                                className="img-fluid"
                                                src={this.props.image.meta.imageDerivatives.links[imageStyle].href}
                                                alt={this.props.image.meta.alt}
                                            />
                                        }

                                        {/* {this.props.meta.field_shape && !this.props.image &&
                                            <Shape shape={this.props.meta.field_shape} />
                                        } */}

                                    </div>
                                </div>
                                <div className={metaTextClasses}>
                                    <div className="inner info d-flex align-items-start justify-content-between w-100">
                                        <div className="flex-column justify-content-between">
                                            {this.props.meta && this.props.meta.field_news_date &&
                                                <div className="p-3">{this.props.meta.field_news_date}</div>
                                            }

                                            {this.props.meta && this.props.meta.field_event_location &&
                                                <div className="location p-3">
                                                    {this.props.meta.field_event_location.organization &&
                                                        <div>{this.props.meta.field_event_location.organization}</div>
                                                    }
                                                    {this.props.meta.field_event_location.address_line1 &&
                                                        <div>{this.props.meta.field_event_location.address_line1}</div>
                                                    }
                                                    <div>{this.props.meta.field_event_location.postal_code} {this.props.meta.field_event_location.locality}</div>
                                                </div>
                                            }


                                        </div>
                                        <div className="flex-column justify-content-between text-right">
                                            {this.props.meta && this.props.meta.field_event_date &&
                                                <div className="p-3">
                                                    <Moment date={new Date(this.props.meta.field_event_date.value)} />
                                                    {m(this.props.meta.field_event_date.value).diff(m(this.props.meta.field_event_date.end_value), 'days') > 0 &&
                                                        <>- <Moment date={new Date(this.props.meta.field_event_date.value)} /></>
                                                    }<br />
                                                    <Moment format="HH:mm" date={new Date(this.props.meta.field_event_date.value)} />
                                        - <Moment format="HH:mm" date={new Date(this.props.meta.field_event_date.end_value)} />
                                                </div>
                                            }
                                            {this.props.type &&
                                                <div className="p-3">
                                                    {this.props.type}
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </header>
        );
    }
}

export default PageHeader
