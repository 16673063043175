import React from 'react';
import Shape from "./../shapes/shape";
import Loader from "./../layout/Loader";
import ClassNames from "classnames";
import S from "string";
import { Link } from 'react-router-dom';
import NodeTeaser from "./NodeTeaser";
import Moment from "react-moment";
import m from "moment";


class EventTeaser extends NodeTeaser{


        render = () => {

            const teaserClasses = ClassNames({
                'teaser story': true,
                ['bg-' + S(this.props.bgColor).chompRight('_color').s] : true,
                ['hv-bg-' + S(this.props.foreColor).chompRight('_color').s] : true,
                ['text-' + S(this.props.foreColor).chompRight('_color').s] : true,
                ['hv-text-' + S(this.props.bgColor).chompRight('_color').s] : true,
            });

            const shapeWrapperClasses = ClassNames({
                'shape' : true,
                'h-100' : true,
                ['svg-' + S(this.props.foreColor).chompRight('_color').s] : true,
                ['hv-svg-' + S(this.props.foreColor).chompRight('_color').s] : true,
                ['hv-bg-' + S(this.props.bgColor).chompRight('_color').s] : true,
                'flip' : false,
            })


            const shapeClasses = ClassNames({
                'h-100' : true,
                'w-100': true,
            })

            const textClasses = ClassNames({
                'half':true
            })

            let path = null;
            let TagName = 'div';
            let opts = {}


            if(this.props.attributes.field_event_type !== 'extern'){
                path = (this.props.path)?this.props.path.alias:'/node/'+this.props.nid;
                TagName = Link;
                opts = {to: path,  className: 'position-relative d-block'};

            }else{
                path = (this.props.attributes.field_event_link.url);
                TagName = 'a';
                opts = {href: path, target:'_blank', className: 'position-relative d-block' };
            }

            return (
            <Loader loaded={this.state.loaded}>
                <TagName {...opts}>
                    <article className={teaserClasses}>
                        <div className={textClasses}>
                            <div className="inner info p-3 d-flex align-items-start justify-content-between w-100">
                                <div className="d-flex flex-column h-100 justify-content-between">
                                    <h2 className="font-size-base m-0 p-0 w-100">
                                        {this.props.title}
                                    </h2>
                                    {this.props.attributes.field_event_location.locality}
                                </div>
                                <div className="d-flex flex-column h-100 justify-content-between align-items-end text-right">

                                    <div className="date"><Moment>{this.props.attributes.field_event_date.value}</Moment>
                                        {m(this.props.attributes.field_event_date.value).diff(m(this.props.attributes.field_event_date.end_value), 'days') > 0 &&
                                        <Moment>{this.props.attributes.field_event_date.end_value}</Moment>
                                        }
                                    </div>

                                    <div className="subtitle">{this.props.attributes.field_sub_title}</div>
                                </div>
                            </div>
                        </div>
                        <div className="half">
                            <div className="inner d-flex flex-column">
                                { this.props.shape &&
                                    <div className={shapeWrapperClasses}>
                                        <Shape shape={this.props.shape} shapeClasses={shapeClasses}/>
                                    </div>
                                }
                            </div>
                        </div>
                    </article>
                </TagName>
            </Loader>
            )
        }


}

export default EventTeaser