import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import HelmetHead from "../layout/HelmetHead";
import PageHeader from "../layout/PageHeader";
import Loader from './../layout/Loader';
import Node from './../nodes/Node.js';
import PageBase from "./PageBase";


class CreativePage extends PageBase {

    _parsePage(page) {
        let content = page.data;
        let logo = null;
        if (logo = page['mediaLogo#uri{0}']) {
            logo = JSON.parse(logo.body).data;
        }


        if (content) {
            this.setState({
                content: content,
                pageTitle: content.attributes.title ? content.attributes.title : null,
                logo: logo,
                loaded: true
            });
        } else {
            this.setState({
                pageNotFound: true
            })
        }
    }

    render = () => {
        return (
            <>
                <>
                    {this.state.pageNotFound !== true &&
                        <section className="page section">
                            <HelmetHead
                                pageTitle={this.state.pageTitle}
                                content={this.state.content}
                                image={this.state.image}
                                url={this.props.match.url}
                                project={this.props.project}
                            ></HelmetHead>
                            <Loader loaded={this.state.loaded}>
                                <PageHeader
                                    pageTitle={this.state.pageTitle}
                                    content={(this.state.content && this.state.content.attributes.body) ? this.state.content.attributes.body.value : ''}
                                    image={(this.state.image && this.state.image.relationships.field_media_image.data) ? this.state.image.relationships.field_media_image.data : null}
                                    imageStyle="large"
                                    meta={this.state.content ? this.state.content.attributes : {}}
                                />
                                {this.state.content &&
                                    <div className="container">
                                        <div className="row no-gutters">
                                            <div className="col-lg-7">
                                                <Node content={this.state.content} nested={true} ></Node>

                                            </div>
                                            <div className="col-lg-4 ml-auto">
                                                {this.state.logo && this.state.logo.relationships.field_media_image.data && this.state.logo.relationships.field_media_image.data.meta.imageDerivatives &&
                                                    <img className="img-fluid mb-5" src={this.state.logo.relationships.field_media_image.data.meta.imageDerivatives.links.medium_scale.href} alt={this.state.logo.relationships.field_media_image.data.meta.alt} />
                                                }
                                                <div className="bg-light p-3">
                                                    {(this.state.content.attributes.field_company_location.address_line1 || this.state.content.attributes.field_company_location.locality) &&
                                                        <div class="address mb-3">
                                                            {
                                                                this.state.content.attributes.field_company_location.address_line1 &&
                                                                <div className="address-line">
                                                                    {this.state.content.attributes.field_company_location.address_line1}
                                                                </div>
                                                            }
                                                            {this.state.content.attributes.field_company_location.locality &&
                                                                <div className="location">
                                                                    {this.state.content.attributes.field_company_location.postal_code} {this.state.content.attributes.field_company_location.locality}
                                                                </div>
                                                            }
                                                        </div>
                                                    }

                                                    {(this.state.content.attributes.field_company_phone || this.state.content.attributes.field_email) &&
                                                        <div className="phone-email mb-3">
                                                            {this.state.content.attributes.field_company_phone &&
                                                                <><a href={'tel:' + this.state.content.attributes.field_company_phone}>{this.state.content.attributes.field_company_phone}</a><br /></>
                                                            }
                                                            {this.state.content.attributes.field_email &&
                                                                <><a href={'mailto:' + this.state.content.attributes.field_email}>{this.state.content.attributes.field_email}</a></>
                                                            }
                                                        </div>
                                                    }

                                                    {this.state.content.attributes.field_company_website &&
                                                        <div className="website mb-3">
                                                            <><a href={this.state.content.attributes.field_company_website.uri} target="_blank" rel="noopener noreferrer">{this.state.content.attributes.field_company_website.uri}</a></>
                                                        </div>
                                                    }

                                                    {(this.state.content.attributes.field_company_facebook || this.state.content.attributes.field_company_twitter || this.state.content.attributes.field_company_linkedin) &&
                                                        <div className="socials mb-3">
                                                            {this.state.content.attributes.field_company_facebook &&
                                                                <a className="d-inline-block mr-2 font-size-lg" href={this.state.content.attributes.field_company_facebook.uri} target="_blank" rel="noopener noreferrer"><FontAwesomeIcon size="1x" icon={['fab', 'facebook']} /></a>
                                                            }
                                                            {this.state.content.attributes.field_company_twitter &&
                                                                <a className="d-inline-block mx-2 font-size-lg" href={this.state.content.attributes.field_company_twitter.uri} target="_blank" rel="noopener noreferrer"><FontAwesomeIcon size="1x" icon={['fab', 'twitter']} /></a>
                                                            }
                                                            {this.state.content.attributes.field_company_ &&
                                                                <a className="d-inline-block mx-2 font-size-lg" href={this.state.content.attributes.field_company_.uri} target="_blank" rel="noopener noreferrer"><FontAwesomeIcon size="1x" icon={['fab', 'instagram']} /></a>
                                                            }
                                                            {this.state.content.attributes.field_company_linkedin &&
                                                                <a className="d-inline-block mx-2 font-size-lg" href={this.state.content.attributes.field_company_linkedin.uri} target="_blank" rel="noopener noreferrer"><FontAwesomeIcon size="1x" icon={['fab', 'linkedin']} /></a>
                                                            }
                                                        </div>
                                                    }


                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </Loader>
                        </section>
                    }
                </>
                <>
                    {this.state.pageNotFound === true &&
                        <Redirect
                            to={{
                                pathname: "/404",
                                state: { from: this.props.location }
                            }}
                        />
                    }
                </>
            </>
        )

    }

}

export default withRouter(CreativePage);