import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ClassNames from 'classnames';
import React from 'react';
import Lightbox from 'react-images';
import Paragraph from "./Paragraph";


class ParagraphMediaImageGallery extends Paragraph {

  constructor(props) {
    super(props);
    this.lightbox = React.createRef();
    this.state = {
      images: [],
      content: null,
      thumbs: null,
    };
  }

  _fetchParagraph = () => {

    let apiPath = this.props.type.replace('--', '/') + '/' + this.props.id + '?include=field_images';
    const api = process.env.REACT_APP_API_URL + '/jsonapi/' + apiPath;

    fetch(api, {
      method: 'GET',
      headers: {
        'Accept': 'application/json'
      }
    }).then((resp) => {
      return resp.json();
    })
      .then((paragraph) => {
        const mediaImages = paragraph.included.map(element => {
          return element
        })

        let images = []
        for (var i in mediaImages) {
          images.push({
            caption: mediaImages[i].relationships.field_media_image.data.meta.title,
            src: mediaImages[i].relationships.field_media_image.data.meta.imageDerivatives.links.large_scale.href,
          })
        }

        this.setState({
          thumbs: this._renderImageGallery('medium', mediaImages),
          images: images,
        })
      }).catch((error) => {
        console.log(error);
      });

  }


  _renderImageGallery = (image_style, image_meta) => {
    if (!image_style) {
      image_style = 'medium_landscape';
    }
    if (image_meta && image_meta) {

      return image_meta.map((item, key) => {
        return (
          <a key={key} href="#" className="col-sm-6 col-md-4 col-lg-3 position-relative"
            onClick={(e) => this._openLightBox(e, key)}>
            <img
              key={item.id}
              className="img-fluid"
              alt={item.relationships.field_media_image.data.meta.alt}
              src={item.relationships.field_media_image.data.meta.imageDerivatives.links[image_style].href} />
            <div
              className="position-absolute w-100 h-100 d-flex justify-content-center align-items-center text-white bg-overlay bg-primary">
              <FontAwesomeIcon icon={['far', 'plus']} size="2x" />
            </div>
          </a>

        )
      });
    } else {
      return false;
    }
  }

  _closeLightbox = () => {
    this.setState({
      lightBoxOpen: false
    })
  }

  _openLightBox = (e, index) => {
    e.preventDefault();
    this.setState({
      lightBoxOpen: true,
      lightBoxImage: index
    })
  }

  _nextLightBox = () => {
    let index = this.state.lightBoxImage;
    this.setState({
      lightBoxImage: index += 1
    })
  }

  _prevLightBox = () => {
    let index = this.state.lightBoxImage;
    this.setState({
      lightBoxImage: index -= 1
    })
  }

  render = () => {
    const titleClasses = ClassNames({
      'paragraph--title': true,
      [(!this.props.nested) ? 'offset-lg-2' : 'offset-0']: true,
      'mb-3': true,
    })
    return (
      <div className='paragraph paragraph--image-gallery mb-5'>
        {this.state.content && this.state.content.attributes.field_title &&
          <h2 className={titleClasses}>{this.state.content.attributes.field_title}</h2>
        }

        {this.state.content && this.state.content.attributes.field_body &&
          <div className='pargraph--body'
            dangerouslySetInnerHTML={{ __html: this.state.content.attributes.field_body.value }}>
          </div>
        }
        {this.state.images &&
          <div className="image-gallery row no-gutters justify-content-center">
            {this.state.thumbs}
            <Lightbox
              ref={this.lightbox}
              images={this.state.images}
              currentImage={(this.state.lightBoxImage ? this.state.lightBoxImage : 0)}
              isOpen={(this.state.lightBoxOpen) ? true : false}
              onClose={() => {
                this._closeLightbox()
              }}
              onClickNext={() => {
                this._nextLightBox()
              }}
              onClickPrev={() => {
                this._prevLightBox()
              }}
              backdropClosesModal={true}
            />

          </div>
        }

      </div>
    );
  }
}

export default ParagraphMediaImageGallery;
