import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component } from 'react';
import { Link } from "react-router-dom";

class NodeTeaser extends Component {

  constructor(props) {
    super(props);
    this.state = {
      image: null,
      loaded: false
    };
  } $

  componentDidMount() {
    if (this.props.image) {
      this.setState({
        image: this.props.image.meta,
        loaded: true
      })
    } else {
      this.setState({
        loaded: true
      })
    }
  }

  _renderImage = (image_style) => {
    if (!image_style) {
      image_style = 'medium_landscape';
    }
    if (this.state.image && this.state.image.imageDerivatives) {
      return (
        <img src={this.state.image.imageDerivatives.links[image_style].href}
          className="img-fluid"
          alt={this.state.image.alt}
        />
      )
    } 
  }

  render = () => {

    let path = null;
    let TagName = 'div';
    let opts = [];

    if (this.props.hasDetail !== false) {
      path = (this.props.path) ? this.props.path.alias : '/node/' + this.props.nid;
      if (this.props.isExternal) {
        TagName = 'a';
        opts['href'] = path;
      } else {
        TagName = Link;
        opts['to'] = path;
      }

    }

    return (
      <TagName {...opts} className="position-relative d-block text-secondary">
        <article className="teaser">
          {this.props.image && this._renderImage('medium_landscape')}
          <h2>
            {this.props.title}
          </h2>
          <p>
            {this.props.text}
          </p>
          {path &&
            <u>Meer over {this.props.title} <FontAwesomeIcon icon={['far', 'arrow-right']} /></u>
          }
        </article>
      </TagName>
    )
  }
}

export default NodeTeaser;
