import React from 'react';
import Loader from './../layout/Loader';
import {withRouter, Redirect} from 'react-router-dom';
import Node from './../nodes/Node.js';
import PageHeader from "../layout/PageHeader";
import PageBase from "./PageBase";
import HelmetHead from "../layout/HelmetHead";
import RelatedContent from "../field/RelatedContent";


class EventPage extends PageBase {

    _buildBluePrint = () => {
        return [
            {
                'requestId': 'router',
                'uri': process.env.REACT_APP_API_URL + '/router/translate-path?path=' + this.props.location.pathname,
                'action': 'view',
                'headers': {
                    'Content-Type': 'application/vnd.api+json',
                    'X-Consumer-ID': process.env.REACT_APP_CONSUMER_ID
                },
            },
            {
                'requestId': 'page',
                'action': 'view',
                'uri': process.env.REACT_APP_API_URL + '/jsonapi/{{router.body@$.entity.type}}/{{router.body@$.entity.bundle}}' +
                    '?filter[project-node][group][conjunction]=AND' +
                    '&filter[project-field][condition][path]=field_project.id&filter[project-field][condition][value]=' + process.env.REACT_APP_PROJECT_ID + '&filter[project-field][condition][memberOf]=project-node' +
                    '&filter[page-id][condition][path]=id&filter[page-id][condition][value]={{router.body@$.entity.uuid}}&filter[page-id][condition][memberOf]=project-node',
                'headers': {
                    'Content-Type': 'application/vnd.api+json',
                    'X-Consumer-ID': process.env.REACT_APP_CONSUMER_ID
                },
                'waitFor': [
                    'router'
                ]
            },
            {
                'requestId' : 'mediaImage',
                'action' : 'view',
                'uri' : '{{page.body@$.data[0].relationships.field_media_image.links.related.href}}',
                'headers' : {
                    'Content-Type' : 'application/vnd.api+json',
                    'X-Consumer-ID' : process.env.REACT_APP_CONSUMER_ID
                },
                'waitFor' : [
                    'page'
                ]
            },
            {
                'requestId' : 'creatives',
                'action' : 'view',
                'uri' : '{{page.body@$.data[0].relationships.field_creatives.links.related.href}}',
                'headers' : {
                    'Content-Type' : 'application/vnd.api+json',
                    'X-Consumer-ID' : process.env.REACT_APP_CONSUMER_ID
                },
                'waitFor' : [
                    'page'
                ]
            },
            {
                'requestId' : 'related',
                'action' : 'view',
                'uri' : '{{page.body@$.data[0].relationships.field_related_content.links.related.href}}',
                'headers' : {
                    'Content-Type' : 'application/vnd.api+json',
                    'X-Consumer-ID' : process.env.REACT_APP_CONSUMER_ID
                },
                'waitFor' : [
                    'page'
                ]
            }
        ];
    }

    _parsePage(page){
        let content = JSON.parse(page['page#uri{0}'].body);
        let image = null;
        if(image = page['mediaImage#uri{0}']){
            image = JSON.parse(image.body).data;
        }
        let creatives = null;
        if(creatives = page['creatives#uri{0}']){
            creatives = JSON.parse(creatives.body).data;
        }


        if(content.data.length > 0){
            this.setState({
                content: content.data[0],
                pageTitle: content.data[0].attributes.title,
                image: image,
                creatives: creatives,
                loaded: true
            });
        }else{
            this.setState({
                pageNotFound: true
            })
        }
    }

    render = () => {
        return (
            <>
                <>
                    {this.state.pageNotFound !== true &&
                    <section className="page section">
                        <HelmetHead
                            pageTitle={this.state.pageTitle}
                            content={this.state.content}
                            image={this.state.image}
                            url={this.props.match.url}
                            project={this.props.project}
                        ></HelmetHead>
                        <Loader loaded={this.state.loaded}>
                            <PageHeader
                                pageTitle={this.state.pageTitle}
                                content={(this.state.content && this.state.content.attributes.body)?this.state.content.attributes.body.value:''}
                                image={(this.state.image && this.state.image.relationships.field_media_image.data)?this.state.image.relationships.field_media_image.data:null}
                                type="event"
                                meta={(this.state.content && this.state.content.attributes)?this.state.content.attributes:''}
                            />
                            {this.state.content &&
                            <div className="container">
                                <div className="row no-gutters">
                                    <div className="col-lg-7">
                                        <Node content={this.state.content} nested={true}></Node>
                                    </div>
                                    {this.state.creatives && this.state.creatives.length  > 0 &&
                                        <div className="col-lg-4 ml-auto">
                                            <div className="bg-light py-5">
                                                {this._renderCreatives()}
                                            </div>
                                        </div>
                                    }
                                </div>
                                {this.state.related && <RelatedContent nodes={this.state.related} title={this.state.content.attributes.field_related_content_title} className="mb-5"/>}
                            </div>
                            }
                        </Loader>
                    </section>
                    }
                </>
                <>
                    {this.state.pageNotFound === true &&
                    <Redirect
                        to={{
                            pathname: "/404",
                            state: {from: this.props.location}
                        }}
                    />
                    }
                </>
            </>
        )

    }

}

export default withRouter(EventPage);