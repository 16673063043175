import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { Collapse, Nav, Navbar, NavbarBrand, NavbarToggler, NavItem } from 'reactstrap';

class Menu extends Component {

    constructor(props) {
        super(props);
        this.state = {
            menu: [],
            isOpen: false
        }
    }

    componentDidMount() {
        this._fetchMenu();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps !== this.props && this.props.menuName !== null) {
            this._fetchMenu();
        }
    }

    _fetchMenu = () => {
        const api = process.env.REACT_APP_API_URL + '/project-sites/load-menu/' + this.props.menuName;
        fetch(api, {
            method: 'GET',
        }).then((resp) => {
            return resp.json();
        })
            .then((menu) => {
                this.setState({
                    'menu': menu
                })
            })
            .catch((error) => {
                console.log(error);
            });
    }

    _getMenuItems = () => {
        const menu = this.state.menu;
        if (menu.length) {
            return menu.sort((a, b) => {
                return a.weight.value - b.weight.value;
            }).map((item, key) => {
                let alias = item.url_alias;
                let uri = item.uri.replace("internal:", "")
                let LinkTag = NavLink
                let opts = { to: alias, className: 'nav-link text-uppercase', exact: true, key: item.id };
                if (!(alias.length > 0)) {
                    LinkTag = 'a';
                    opts = { href: uri, target: '_blank', className: 'nav-link text-secondary text-uppercase', key: item.id };
                }

                if (item.enabled.value === "1") {
                    return (
                        <NavItem key={item.id}>
                            <LinkTag {...opts}>{item.title}</LinkTag>
                        </NavItem>
                    );
                }
            });
        } else {
            return [];
        }
    }

    _toggle() {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }

    render = () => {
        const menuItems = this._getMenuItems();
        return (
            <Navbar color="faded" expand="lg">
                <div className={'container'}>
                    <div className="row no-gutters w-100">
                        <div className=" col-8 col-md-4 col-lg-4 mr-auto pl-3">
                            <Link to="/">
                                <NavbarBrand tag="div" className="d-flex justify-content-left">
                                    {this.props.logo &&
                                        <img className="logo img-fluid" src={this.props.logo} />
                                    }
                                </NavbarBrand>
                            </Link>
                        </div>
                        <NavbarToggler className="border-0 mr-3" onClick={() => { this._toggle() }}>
                            <FontAwesomeIcon icon={['far', 'bars']} size="1x" className="ml-2 animate-down d-inline-block" />
                        </NavbarToggler>
                        <Collapse isOpen={this.state.isOpen} navbar className="col-lg-8">
                            {menuItems &&
                                <Nav navbar className="bg-header py-5 py-lg-1">
                                    {menuItems}
                                </Nav>
                            }
                        </Collapse>
                    </div>
                </div>
            </Navbar>
        )
    }

}

export default Menu;
