import React from 'react';
import RelatedContent from "../field/RelatedContent";
import Paragraph from "./Paragraph";

class ParagraphRelatedContent extends Paragraph {

    constructor(props) {
        super(props);
        this.state = {
            content: {}
        };
    }




    _parseParagraph = (paragraph) => {
        const content = paragraph;
        const nodes = paragraph.data.relationships.field_content;

        this.setState({
            content: content.data.attributes,
            nodes: nodes.data
        });
    }

    render = () => {
        return (

            <div className='paragraph paragraph--related no-gutters row justify-content-start mb-5'>
                <div class="col-12">
                    {this.state.nodes &&
                        <RelatedContent nodes={this.state.nodes} nested={this.props.nested} title={(this.state.content && this.state.content.field_title) ? this.state.content.attributes.field_title : null} />
                    }
                </div>
            </div>
        );
    }
}

export default ParagraphRelatedContent;