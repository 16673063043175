import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ClassNames from "classnames";
import React from 'react';
import { Link } from 'react-router-dom';
import S from "string";
import Loader from "./../layout/Loader";
import Shape from "./../shapes/shape";
import NodeTeaser from "./NodeTeaser";


class StoryTeaser extends NodeTeaser {


    render = () => {

        const teaserClasses = ClassNames({
            'teaser story': true,
            ['bg-' + S(this.props.bgColor).chompRight('_color').s]: true,
            ['hv-bg-' + S(this.props.foreColor).chompRight('_color').s]: true,
            ['text-' + S(this.props.foreColor).chompRight('_color').s]: true,
            ['hv-text-' + S(this.props.bgColor).chompRight('_color').s]: true,
        });

        const shapeWrapperClasses = ClassNames({
            'shape': true,
            'h-100': true,
            ['svg-' + S(this.props.foreColor).chompRight('_color').s]: true,
            ['hv-svg-' + S(this.props.foreColor).chompRight('_color').s]: true,
            'flip': true,
        })


        const shapeClasses = ClassNames({
            [(this.props.shape && S(this.props.shape).contains('demi')) ? 'h-50' : 'h-100']: true,
            'w-100': true,
        })

        const textClasses = ClassNames({
            'half': true
        })



        return (
            <Loader loaded={this.state.loaded}>
                <Link className="position-relative d-block" to={this.props.path ? this.props.path.alias : 'node/' + this.props.nid}>
                    <article className={teaserClasses}>
                        <div className="square">
                            {this.props.image &&
                                <div className="inner">
                                    {this._renderImage('medium')}
                                </div>
                            }
                            <div className="inner d-flex flex-column">
                                {this.props.shape &&
                                    <div className={shapeWrapperClasses}>
                                        <Shape shape={this.props.shape} shapeClasses={shapeClasses} />
                                        {S(this.props.shape).contains('demi') &&
                                            <Shape shape={this.props.shape} shapeClasses={shapeClasses} />
                                        }
                                    </div>
                                }
                            </div>
                        </div>
                        <div className={textClasses}>
                            <div className="inner info p-3 d-flex align-items-start justify-content-between w-100">
                                <div className="d-flex flex-column h-100 justify-content-between">
                                    <h2 className="font-size-base m-0 p-0 w-100">
                                        {this.props.title}
                                    </h2>
                                    story
                                </div>
                                <div className="d-flex flex-column h-100 justify-content-between align-items-end">
                                    <FontAwesomeIcon icon={['far', 'arrow-up']} className="ml-2 fa-arrow-up animate-top d-inline-block" />
                                </div>
                            </div>
                        </div>
                    </article>
                </Link>
            </Loader>
        )
    }


}

export default StoryTeaser