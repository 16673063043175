import React, { Component } from 'react';
import Loader from "./../layout/Loader";

class Paragraph extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loaded: false,
            content: {}
        };
    }

    componentDidMount() {
        this._fetchParagraph(this.props.id);
    }

    _buildBluePrint = () => {
        let apiPath = this.props.type.replace('--', '/') + '/' + this.props.id;
        return [
            {
                'requestId': 'paragraph',
                'action': 'view',
                'uri': process.env.REACT_APP_API_URL + '/jsonapi/' + apiPath,
                'headers': {
                    'Content-Type': 'application/vnd.api+json',
                    'X-Consumer-ID': process.env.REACT_APP_CONSUMER_ID,
                    'Accept': 'application/vnd.api+json'
                },
            }
        ];
    }



    _fetchParagraph = (id) => {
        let apiPath = this.props.type.replace('--', '/') + '/' + id;
        const api = process.env.REACT_APP_API_URL + '/jsonapi/' + apiPath;

        fetch(api, {
            method: 'GET',
            headers: {
                'Accept': 'application/json'
            }
        }).then((resp) => {
            return resp.json();
        })
            .then((paragraph) => {
                this._parseParagraph(paragraph);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    _parseParagraph = (paragraph) => {
        const content = paragraph;
        this.setState({
            content: content.data,
            loaded: true
        });
    }

    render = () => {
        return (
            <Loader loaded={this.state.loaded}>
                <div className='paragraph row justify-content-center mb-5'>

                    <div className={(this.props.nested) ? "col-lg-12" : "col-lg-8"}>
                        {this.state.content.attributes && this.state.content.attributes.field_title &&
                            <h2 className='pargraph--title'>{this.state.content.attributes.field_title}</h2>
                        }

                        {this.state.content.attributes && this.state.content.attributes.field_body &&
                            <div className='pargraph--body' dangerouslySetInnerHTML={{ __html: this.state.content.attributes.field_body.value }}>
                            </div>
                        }
                    </div>
                </div>
            </Loader>
        );
    }
}

export default Paragraph;
