import {withRouter} from 'react-router-dom';
import PageBase from "./PageBase";


class Page extends PageBase {

    _buildBluePrint = () => {
        return [
            {
                'requestId': 'router',
                'uri': process.env.REACT_APP_API_URL + '/router/translate-path?path=' + this.props.location.pathname,
                'action': 'view',
                'headers': {
                    'Content-Type': 'application/vnd.api+json',
                    'X-Consumer-ID': process.env.REACT_APP_CONSUMER_ID
                },
            },
            {
                'requestId': 'page',
                'action': 'view',
                'uri': process.env.REACT_APP_API_URL + '/jsonapi/{{router.body@$.entity.type}}/{{router.body@$.entity.bundle}}' +
                    '?filter[project-node][group][conjunction]=AND' +
                    '&filter[project-field][condition][path]=field_project.id&filter[project-field][condition][value]=' + process.env.REACT_APP_PROJECT_ID + '&filter[project-field][condition][memberOf]=project-node' +
                    '&filter[page-id][condition][path]=id&filter[page-id][condition][value]={{router.body@$.entity.uuid}}&filter[page-id][condition][memberOf]=project-node',
                'headers': {
                    'Content-Type': 'application/vnd.api+json',
                    'X-Consumer-ID': process.env.REACT_APP_CONSUMER_ID,
                    'Accept': 'application/vnd.api+json'
                },
                'waitFor': [
                    'router'
                ]
            },
            {
                'requestId' : 'mediaImage',
                'action' : 'view',
                'uri' : '{{page.body@$.data[0].relationships.field_media_image.links.related.href}}',
                'headers' : {
                    'Content-Type' : 'application/vnd.api+json',
                    'X-Consumer-ID' : process.env.REACT_APP_CONSUMER_ID,
                    'Accept': 'application/vnd.api+json'
                },
                'waitFor' : [
                    'page'
                ]
            },
            {
                'requestId' : 'related',
                'action' : 'view',
                'uri' : '{{page.body@$.data[0].relationships.field_related_content.links.related.href}}',
                'headers' : {
                    'Content-Type' : 'application/vnd.api+json',
                    'X-Consumer-ID' : process.env.REACT_APP_CONSUMER_ID,
                    'Accept': 'application/vnd.api+json'
                },
                'waitFor' : [
                    'page'
                ]
            }
        ];
    }

    

}

export default withRouter(Page);