import React from 'react';
import Banner from "../entities/Banner";
import Paragraph from "./Paragraph";

class ParagraphBanners extends Paragraph {


    _fetchParagraph = () => {

        let apiPath = this.props.type.replace('--', '/') + '/' + this.props.id;
        const api = process.env.REACT_APP_API_URL + '/jsonapi/' + apiPath;

        fetch(api, {
            method: 'GET',
            headers: {
                'Accept': 'application/json'
            }
        }).then((resp) => {
            return resp.json();
        })
            .then((paragraph) => {
                const urls = paragraph.data.relationships.field_banners.data.map(element => {
                    return process.env.REACT_APP_API_URL + '/jsonapi/banner/banner/' + element.id + '?include=field_image'
                })
                Promise.all(urls.map(url =>
                    fetch(url)
                ))
                    .then(responses => Promise.all(responses.map(r => r.json())))
                    .then((elements) => {
                        this._parseBanner(elements)
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            }).catch((error) => {
                console.log(error);
            });

    }



    _parseBanner = (elements) => {
        const banners = elements;
        this.setState({
            banners: banners,
        });
    }

    render = () => {
        return (
            <div className='paragraph paragraph--banners row no-gutters justify-content-center mb-5'>
                {this.state.banners && this.state.banners.length &&
                    this.state.banners.map((item, key) => {
                        return (
                            <div className="col-md-6 col-lg-4" key={key}>
                                <Banner
                                    key={item.data.id}
                                    title={item.data.attributes.name}
                                    link={item.data.attributes.field_link}
                                    bgColor={item.data.attributes.field_background_color}
                                    foreColor={item.data.attributes.field_text_color}
                                    shape={item.data.attributes.field_shape}
                                    text={item.data.attributes.field_text}
                                    image={(item.included) ? item.included[0].relationships.field_media_image.data : null}
                                />
                            </div>
                        )

                    })}
            </div>
        );
    }
}

export default ParagraphBanners;
