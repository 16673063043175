import React from 'react';
import Paragraph from "./Paragraph";

class ParagraphQuote extends Paragraph {

    constructor(props) {
        super(props);
        this.state = {
            content: {}
        };
    }





    _parseParagraph = (paragraph) => {
        const content = paragraph;
        this.setState({
            content: content.data.attributes
        });
    }

    render = () => {
        return (

            <div className='paragraph paragraph--quote mb-3 row justify-content-center'>
                <div className={this.props.nested ? 'col-12' : 'col-lg-8'}>
                    {this.state.content && this.state.content.field_text &&
                        <blockquote className="blockquote">
                            <p className="text-primary">{this.state.content.field_text}</p>
                            <footer className="blockquote-footer"><cite title="Source Title">{this.state.content.field_author}</cite></footer>
                        </blockquote>
                    }
                </div>
            </div>
        );
    }
}

export default ParagraphQuote;