import React from 'react';
import Paragraph from "./Paragraph";
import ParagraphButton from "./ParagraphButton";
import ParagraphColumn from "./ParagraphColumn";
import ParagraphEmbed from "./ParagraphEmbed";
import ParagraphMediaImage from "./ParagraphMediaImage";
import ParagraphQuote from "./ParagraphQuote";
class ParagraphMultipleColumns extends Paragraph {
    paragraphTypes = {
        'paragraph--media_image': ParagraphMediaImage,
        'paragraph--page_block_button': ParagraphButton,
        'paragraph--page_block_embed': ParagraphEmbed,
        'paragraph--page_block_quote': ParagraphQuote,
        'paragraph--page_block_column': ParagraphColumn,
        'paragraph': Paragraph
    }

    constructor(props) {
        super(props);
    }


    componentDidMount() {
        this._fetchParagraph(this.props.id);
    }

    _parseParagraph = (paragraph) => {
        const content = paragraph;


        this.setState({
            content: content.data,
            loaded: true
        });
    }



    _renderColumn = (column) => {
        const paragraphs = column.data;

        if (paragraphs.length) {

            return paragraphs.map((item, key) => {

                let TagName = '';
                if (!(TagName = this.paragraphTypes[item.type])) {
                    TagName = this.paragraphTypes['paragraph'];
                }

                return <TagName
                    key={item.id}
                    id={item.id}
                    type={item.type}
                    nested={true}
                />

            });
        } else {
            return false;
        }
    }


    _parseParagraph = (paragraph) => {
        const content = paragraph;
        this.setState({
            content: content.data,

        });
    }

    render = () => {

        return (

            <div className='paragraph row justify-content-center'>
                <div className={this.props.nested ? 'col-12' : 'col-xl-10'}>
                    {this.state.content.attributes && this.state.content.attributes.field_title &&
                        <h2 className='pargraph--title'>{this.state.content.attributes.field_title}</h2>
                    }

                    {this.state.content.relationships && this.state.content.relationships &&

                        < div className="row">
                            {this._renderColumn(this.state.content.relationships.field_column)}
                        </div>
                    }
                </div>

            </div >
        );
    }
}

export default ParagraphMultipleColumns;
