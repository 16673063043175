import React, {Component} from 'react';
import {Helmet} from "react-helmet";

class HelmetHead extends Component{

    render = () => {
        return(
            <Helmet>
                <title>{(this.props.pageTitle?this.props.pageTitle + ' | ':'') + ((this.props.project) ? this.props.project.name : '')}</title>
                <meta property="og:type" content="article" />
                <meta property="og:title" content={this.props.pageTitle} />
                {this.props.content && this.props.content.attributes && this.props.content.attributes.body && this.props.content.attributes.body.summary &&
                <meta name="description" content={this.props.content.attributes.body.summary}/>
                }
                {this.props.content && this.props.content.attributes && this.props.content.attributes.body && this.props.content.attributes.body.summary &&
                <meta property="og:description" content={this.props.content.attributes.body.summary} />
                }
                {this.props.image  && this.props.image.relationships.field_media_image.data && this.props.image.relationships.field_media_image.data.meta.imageDerivatives &&
                <meta property="og:image" content={this.props.image.relationships.field_media_image.data.meta.imageDerivatives.links.large_scale.href}/>
                }
                <meta property="og:url" content={process.env.REACT_APP_URL+this.props.url}/>
            </Helmet>
        )
    }
}

export default HelmetHead