import React, {Component} from 'react';
import ClassNames from 'classnames';
import { ReactComponent as DemiCircle} from './shape-demi_circle.svg';
import { ReactComponent as DemiHexagon} from './shape-demi_hexagon.svg';
import { ReactComponent as DemiOctagon} from './shape-demi_octagon.svg';
import { ReactComponent as DemiRhomb} from './shape-demi_rhomb.svg';
import { ReactComponent as FullCircle} from './shape-full_circle.svg';
import { ReactComponent as FullHexagon} from './shape-full_hexagon.svg';
import { ReactComponent as FullOctagon} from './shape-full_octagon.svg';
import { ReactComponent as FullRhomb} from './shape-full_rhomb.svg';

class Shape extends Component{

    shapes = {
        'demi_circle' : DemiCircle,
        'demi_hexagon' : DemiHexagon,
        'demi_octagon' : DemiOctagon,
        'demi_rhomb' : DemiRhomb,
        'full_circle' : FullCircle,
        'full_hexagon' : FullHexagon,
        'full_octagon' : FullOctagon,
        'full_rhomb' : FullRhomb,
    }

    _renderShape = () => {
        const shapeClasses = (this.props.shapeClasses?this.props.shapeClasses:ClassNames({'w-100' : true, 'h-100' : true}));
        const ShapeTag = this.shapes[this.props.shape];

        return <ShapeTag className={shapeClasses}/>
    }

    render = () => {

        return (
            <>
            {this._renderShape()}
            </>
        )
    }
}

export default Shape;