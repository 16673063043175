import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component } from 'react';
import Loader from "./../layout/Loader";
class CreativeTeaser extends Component {




  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      content: {}
    };
  }

  componentDidMount() {
    this._fetchCreative(this.props.id);
  }

  _fetchCreative = (id) => {

    const api = process.env.REACT_APP_API_URL + '/jsonapi/node/creative/' + id;


    fetch(api, {
      method: 'GET',
      headers: {
        'Accept': 'application/json'
      }
    }).then((resp) => {
      return resp.json();
    })
      .then((creative) => {
        this._parseCreative(creative);
      })
      .catch((error) => {
        console.log(error);
      });
  }


  _parseCreative = (creative) => {
    this.setState({
      content: creative.data,
      loaded: true
    });
  }


  render = () => {
    return (
      <Loader loaded={this.state.loaded}>
        <div class="col-12 mb-5">
          {this.state.content && this.state.content.attributes && this.state.content.attributes.path &&
            <article class="teaser">
              <a class="position-relative d-block text-secondary" href={this.state.content.attributes.path.alias}>
                {this.state.content.attributes && this.state.content.attributes.title &&
                  <h2>{this.state.content.attributes.title}</h2>
                }

                {this.state.content.attributes && this.state.content.attributes.body.summary &&
                  <p dangerouslySetInnerHTML={{ __html: this.state.content.attributes.body.summary }}>
                  </p>
                }
                <u>Meer over {this.state.content.attributes.title} <FontAwesomeIcon icon={['far', 'arrow-right']} /></u>
              </a>
            </article>
          }
        </div>
      </Loader>
    );
  }
}

export default CreativeTeaser
