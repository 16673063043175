import React from 'react';
import Paragraph from "./Paragraph";

class ParagraphText extends Paragraph {

  constructor(props) {
    super(props);
  }


  componentDidMount() {
    this._fetchParagraph(this.props.id);
  }


  _parseParagraph = (paragraph) => {
    const content = paragraph;
    this.setState({
      content: content.data,
      loaded: true
    });
  }

  render = () => {

    return (
      <>
        {this.state.content.attributes && this.state.content.attributes.field_body &&
          <div
            dangerouslySetInnerHTML={{ __html: this.state.content.attributes.field_body.value }}></div>
        }
      </>
    );
  }
}

export default ParagraphText;