import React from 'react';
import Paragraph from "./Paragraph";
import ParagraphButton from "./ParagraphButton";
import ParagraphMediaImage from "./ParagraphMediaImage";
import ParagraphQuote from "./ParagraphQuote";
import ParagraphText from "./ParagraphText";
import ParagraphEmbed from "./ParagraphEmbed";

class ParagraphColumn extends Paragraph {
  paragraphTypes = {
    'paragraph--media_image': ParagraphMediaImage,
    'paragraph--page_block_button': ParagraphButton,
    'paragraph--page_block_quote': ParagraphQuote,
    'paragraph--page_block_text': ParagraphText,
    'paragraph--page_block_embed': ParagraphEmbed,
    'paragraph': Paragraph
  }

  constructor(props) {
    super(props);
  }


  componentDidMount() {
    this._fetchParagraph(this.props.id);
  }

  _parseParagraph = (paragraph) => {
    const content = paragraph;
    this.setState({
      content: content.data,
      loaded: true
    });
  }



  _renderColumnItem = (columnItem) => {
    const paragraphs = columnItem.data;
    if (paragraphs.length) {
      return paragraphs.map((item, key) => {
        let TagName = '';
        if (!(TagName = this.paragraphTypes[item.type])) {
          TagName = this.paragraphTypes['paragraph'];
        }
        return <TagName
          key={item.id}
          id={item.id}
          type={item.type}
          nested={true}
        />

      });
    } else {
      return false;
    }
  }

  render = () => {

    return (
      <>
        {this.state.content.relationships && this.state.content.relationships.field_paragraph &&
          <div className="col-12 col-md-6">
            {this._renderColumnItem(this.state.content.relationships.field_paragraph)}
          </div>
        }
      </>
    );

  }
}

export default ParagraphColumn;
