import React, {Component} from 'react';
import {Helmet} from "react-helmet";
import Loader from "../layout/Loader";

class PageNotFound extends Component {

    render = () => {
        return (
            <section className="page section">
                <Helmet>
                    <title>{ '404 Page not Found |' + ((this.props.project)?this.props.project.name:'')}</title>
                    <meta name="robots" content="follow, noarchive, noindex"></meta>
                </Helmet>
                <Loader loaded={true} delay={500}>
                <header className="page-header bg-secondary py-5 mb-5">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-8">
                                <h1 className="page-title text-primary text-uppercase">404 Page Not Found</h1>
                                <div className="intro text-muted">
                                    <p>OOPS! De pagina die je zoekt kan niet worden gevonden.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
                </Loader>
            </section>
        )
    }

}

export default PageNotFound;