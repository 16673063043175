import React from 'react';
import Paragraph from "./Paragraph";

class ParagraphButton extends Paragraph {

    constructor(props) {
        super(props);
        this.state = {
            content: {}
        };
    }


    _fetchParagraph = () => {
        let apiPath = this.props.type.replace('--', '/') + '/' + this.props.id;
        const api = process.env.REACT_APP_API_URL + '/jsonapi/' + apiPath;

        fetch(api, {
            method: 'GET',
            headers: {
                'Accept': 'application/json'
            }
        }).then((resp) => {
            return resp.json();
        })
            .then((paragraph) => {
                this._parseParagraph(paragraph);
                if ((paragraph.data.attributes.field_link.uri).includes('entity:node/')) {
                    let nid = paragraph.data.attributes.field_link.uri.replace('entity:node/', '')
                    const url = process.env.REACT_APP_API_URL + '/project-sites/load-path/' + nid
                    return fetch(url)
                }

            }).then((resp) => {
                return resp.json()
            }).then((url) => {
                this._parseUrl(url);
            })
            .catch((error) => {
                console.log(error);
            });
    }


    _parseParagraph = (paragraph) => {
        const content = paragraph;
        this.setState({
            content: content.data.attributes,
            absoluteUrl: content.data.attributes.field_link.uri
        });
    }

    _parseUrl = (url) => {
      let apUrl = url
      if (apUrl.startsWith("/news/")){
        apUrl = process.env.REACT_APP_API_URL + apUrl
      }
        this.setState({
            pathalias: apUrl,
        });
    }

    render = () => {
        let path = ''
        if (this.state.pathalias) {
            path = this.state.pathalias
        } else {
            path = this.state.absoluteUrl
        }


        return (

            <div className="paragraph paragraph--button row justify-content-center mb-5">
                <div className="col-auto">
                    {this.state.content && this.state.content.field_link &&

                        <a href={path} className="border-2 btn btn-outline-primary" target={this.state.content.field_link.options.attributes.target}>
                            {this.state.content.field_link.title}
                        </a>
                    }
                </div>
            </div>
        );
    }
}

export default ParagraphButton;
