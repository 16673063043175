import React, {Component} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

class Loader extends Component{

    constructor(props) {
        super(props);
        this.state = {
            loaded: false,
        }
    }

    componentDidMount(prevProps, prevState, snapshot) {
        this._load();

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps !== this.props) {
           this._load();
        }
    }

    _load = () => {
        if(this.props.loaded){
            setTimeout(()=>{
                this.setState({
                    loaded: true
                })
            }, this.props.delay)

        }
    }

    render = () =>{
        return (
            <>
                <div className={this.state.loaded?'loader loaded':'loader'}>
                    {this.state.loaded && this.props.children}
                </div>
                {!this.state.loaded &&
                <div className="justify-content-center d-flex p-5">
                    <FontAwesomeIcon icon={['far', 'circle-notch']} size="3x" className="text-primary" spin/>
                </div>
                }
            </>

    )
    }
}

export default Loader;