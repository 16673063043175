import React from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import RelatedContent from "../field/RelatedContent";
import CreativeTeaser from '../nodes/CreativeTeaser';
import HelmetHead from "./../layout/HelmetHead";
import Loader from './../layout/Loader';
import PageHeader from "./../layout/PageHeader";
import Node from './../nodes/Node.js';
import PageBase from "./PageBase";


class StoryPage extends PageBase {

  _buildBluePrint = () => {
    return [
      {
        'requestId': 'router',
        'uri': process.env.REACT_APP_API_URL + '/router/translate-path?path=' + this.props.location.pathname,
        'action': 'view',
        'headers': {
          'Content-Type': 'application/vnd.api+json',
          'X-Consumer-ID': process.env.REACT_APP_CONSUMER_ID
        },
      },
      {
        'requestId': 'page',
        'action': 'view',
        'uri': process.env.REACT_APP_API_URL + '/jsonapi/{{router.body@$.entity.type}}/{{router.body@$.entity.bundle}}' +
          '?filter[project-node][group][conjunction]=AND' +
          '&filter[project-field][condition][path]=field_project.id&filter[project-field][condition][value]=' + process.env.REACT_APP_PROJECT_ID + '&filter[project-field][condition][memberOf]=project-node' +
          '&filter[page-id][condition][path]=id&filter[page-id][condition][value]={{router.body@$.entity.uuid}}&filter[page-id][condition][memberOf]=project-node',
        'headers': {
          'Content-Type': 'application/vnd.api+json',
          'X-Consumer-ID': process.env.REACT_APP_CONSUMER_ID
        },
        'waitFor': [
          'router'
        ]
      },
      {
        'requestId': 'mediaImage',
        'action': 'view',
        'uri': '{{page.body@$.data[0].relationships.field_media_image.links.related.href}}',
        'headers': {
          'Content-Type': 'application/vnd.api+json',
          'X-Consumer-ID': process.env.REACT_APP_CONSUMER_ID
        },
        'waitFor': [
          'page'
        ]
      },
      {
        'requestId': 'creatives',
        'action': 'view',
        'uri': '{{page.body@$.data[0].relationships.field_creatives.links.related.href}}',
        'headers': {
          'Content-Type': 'application/vnd.api+json',
          'X-Consumer-ID': process.env.REACT_APP_CONSUMER_ID
        },
        'waitFor': [
          'page'
        ]
      },
      {
        'requestId': 'related',
        'action': 'view',
        'uri': '{{page.body@$.data[0].relationships.field_related_content.links.related.href}}',
        'headers': {
          'Content-Type': 'application/vnd.api+json',
          'X-Consumer-ID': process.env.REACT_APP_CONSUMER_ID
        },
        'waitFor': [
          'page'
        ]
      }
    ];
  }
  constructor(props) {
    super(props);
  }


  _parsePage(page) {
    let image = null;
    if (image = page['mediaImage#uri{0}']) {
      image = JSON.parse(image.body).data;
    }
    let creative = page.data.relationships.field_creatives.data
    if (page) {
      this.setState({
        content: page.data,
        pageTitle: page.data.attributes.title,
        image: image,
        creative: creative,
        loaded: true
      });
    } else {
      this.setState({
        pageNotFound: true
      })
    }
  }



  // _renderCreative = () => {
  //   const creatives = this.state.creatives;
  //   if (creatives && creatives.length) {
  //     let TagName = NodeTeaser;
  //     let path = false;
  //     let isExternal = false
  //     return (
  //       <div className="col-12 mb-5">
  //         <TagName
  //           key={this.state.id}
  //           nid={this.state.content.attributes.drupal_internal__nid}
  //           title={this.state.content.attributes.title}
  //           path={path}
  //           isExternal={isExternal}
  //           text={(this.state.content.attributes.body) ? this.state.content.attributes.body.summary : ''}
  //           // image={(this.state.relationships.field_media_image) ? this.state.relationships.field_media_image.data : null}
  //           hasDetail={(this.state.content.attributes.field_detail && path) ? this.state.content.attributes.field_detail : false}
  //         />
  //       </div>
  //     )
  //   } else {
  //     return false;
  //   }
  // }


  render = () => {
    return (
      <>
        <>
          {this.state.pageNotFound !== true &&

            <section className="page section">
              <HelmetHead
                pageTitle={this.state.pageTitle}
                content={this.state.content}
                image={this.state.image}
                url={this.props.match.url}
                project={this.props.project}
              ></HelmetHead>
              <Loader loaded={this.state.loaded}>
                <PageHeader
                  pageTitle={this.state.pageTitle}

                  content={(this.state.content && this.state.content.attributes.body) ? this.state.content.attributes.body.value : ''}
                  image={(this.state.image && this.state.image.relationships.field_media_image.data) ? this.state.image.relationships.field_media_image.data : null}
                  imageStyle="large"
                  meta={this.state.content ? this.state.content.attributes : {}}
                />
                {this.state.content &&
                  <div className="container">
                    <div className="row no-gutters">
                      <div className="col-lg-7">
                        <Node content={this.state.content} nested={true}></Node>
                      </div>

                      {this.state.creative && this.state.creative.length > 0 &&
                        <div className="col-lg-4 ml-auto">
                          <div className="bg-light py-5">
                            {/* {this._renderCreatives(this.state.creative[0].id)} */}

                            <CreativeTeaser id={this.state.creative[0].id} />

                          </div>
                        </div>
                      }
                    </div>
                    {this.state.related && <RelatedContent nodes={this.state.related} title={this.state.content.attributes.field_related_content_title} className="mb-5" />}
                  </div>
                }
              </Loader>
            </section>
          }
        </>
        <>
          {this.state.pageNotFound === true &&
            <Redirect
              to={{
                pathname: "/404",
                state: { from: this.props.location }
              }}
            />
          }
        </>
      </>
    )

  }

}

export default withRouter(StoryPage);
