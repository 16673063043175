import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import Node from './../nodes/Node.js';

class Front extends Component {

    constructor(props) {
        super(props);
        this.state = {
            content: this.props.content.data
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps !== this.props) {
            this.setState({
                content: this.props.content.data,
            });
        }
    }

    render = () => {
        return (
            <div className="container">

                <Helmet>
                    <title>{((this.state.content) ? this.state.content.attributes.name : '')}</title>
                </Helmet>
                {   this.state.content &&
                    <Node content={this.state.content}></Node>
                }
            </div>
        )
    }

}

export default Front;
