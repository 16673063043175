import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ClassNames from 'classnames';
import React, { Component } from 'react';
import S from 'string';
import Loader from "../layout/Loader";
import Shape from "../shapes/shape";

class Banner extends Component {

    constructor(props) {
        super(props);
        this.state = {
            image: null,
            loaded: false
        };
    }

    componentDidMount() {
      this.setState( {
        loaded: true
      } );
      if (this.props.link){
        if (this.props.link.uri.includes('entity:node/')) {
          return this._fetchUrl()
        }
    }
    }

  _fetchUrl= () => {
      let api ="";
      let nid = this.props.link.uri.replace('entity:node/', '')
       api = process.env.REACT_APP_API_URL + '/project-sites/load-path/' + nid
      fetch(api, {
        method: 'GET',
        headers: {
          'Accept': 'application/json'
        }
      }).then((resp) => {
        return resp.json();
      }).then((url) => {
        this.setState({
          url:process.env.REACT_APP_URL + url,
          loaded:true
        })
      })
        .catch((error) => {
          console.log(error);
        });
  }

    _renderImage = () => {
        if (this.props.image && this.props.image.meta.imageDerivatives) {
            return (
                <img src={this.props.image.meta.imageDerivatives.links.medium.href}
                    alt={this.props.image.meta.alt}
                    className="img-fluid" />
            )
        }
    }

    render = () => {

        const bannerClasses = ClassNames({
            'banner': true,
            ['bg-' + S(this.props.bgColor).chompRight('_color').s]: true,
            ['hv-bg-' + S(this.props.foreColor).chompRight('_color').s]: true,
            ['text-' + S(this.props.foreColor).chompRight('_color').s]: true,
            ['hv-text-' + S(this.props.bgColor).chompRight('_color').s]: true,
            'square': true,
        });

        const overlayClasses = ClassNames({
            'inner': true,
            'bg-overlay': true,
            ['bg-' + S(this.props.bgColor).chompRight('_color').s]: true,
        })

        const shapeClasses = ClassNames({
            'shape': true,
            'h-50': true,
            ['svg-' + S(this.props.foreColor).chompRight('_color').s]: true,
            ['bg-' + S(this.props.bgColor).chompRight('_color').s]: true,
            'flip': true,
        })

        const textClasses = ClassNames({
            [(this.props.shape) ? 'h-50' : 'h-100']: true,
            'p-3': true
        })

        return (
            <Loader loaded={this.state.loaded}>
                <a className="position-relative d-block" href={this.props.link ? this.state.url : '#'} target={this.props.link ? this.props.link.options.attributes.target : '_self'}>
                    <article className={bannerClasses}>
                        {this.props.image &&
                            <div className="inner">
                                {this._renderImage()}
                                <div className={overlayClasses}></div>
                            </div>
                        }
                        <div className="inner d-flex flex-column">
                            {this.props.shape &&
                                <div className={shapeClasses}>
                                    <Shape shape={this.props.shape} />
                                </div>
                            }
                            <div className={textClasses}>
                                <div className="d-flex flex-column justify-content-between h-100">
                                    <h1 className="d-flex align-items-top justify-content-between h2">
                                        <span>{this.props.title}</span>
                                        <FontAwesomeIcon icon={['far', 'arrow-down']} size="1x" className="ml-2 animate-down d-inline-block font-size-base" />
                                    </h1>
                                    {this.props.text &&
                                        <div>{this.props.text}</div>
                                    }
                                    {this.props.link &&
                                        <div>
                                            {this.props.link.title &&
                                                <u>{this.props.link.title}</u>
                                            }
                                        </div>
                                    }
                                </div>
                            </div>

                        </div>
                    </article>
                </a>
            </Loader>
        )
    }

}

export default Banner;
