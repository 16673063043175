import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import RelatedContent from "./../field/RelatedContent";
import HelmetHead from "./../layout/HelmetHead";
import Loader from './../layout/Loader';
import PageHeader from "./../layout/PageHeader";
import EventTeaser from "./../nodes/EventTeaser";
import NewsTeaser from "./../nodes/NewsTeaser";
import Node from './../nodes/Node.js';
import NodeTeaser from "./../nodes/NodeTeaser";
import StoryTeaser from "./../nodes/StoryTeaser";


class PageBase extends Component {

    nodeTypes = {
        'node--story': StoryTeaser,
        'node--news': NewsTeaser,
        'node--event': EventTeaser
    }

    constructor(props) {
        super(props);
        this.state = {
            pageTitle: '',
            loaded: false
        };
    }

    componentDidMount() {
        this._fetchPage();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.location.pathname !== this.props.location.pathname) {
            this._fetchPage();
        }
    }



    _buildBluePrint = () => {
        return [];
    }



    _fetchPage = () => {
        let type = this.props.location.pathname.split('/')[1]
        let imgIncl = ''
        switch (type) {
            case 'stories':
                type = 'story'
                break;
            case 'news':
                type = 'news'
                imgIncl = '?include=field_media_image'
                break;
            case 'creatives':
                type = 'creative'
                break;
            default:
                type = 'page'
        }
        const api = process.env.REACT_APP_API_URL + '/project-sites/load-pages/' + type
        fetch(api, {
            method: 'GET',
            headers: { 'accept': 'application/json' }
        }).then((resp) => {
            return resp.json();
        })
            .then((pages) => {
                pages = pages.filter((a) => {
                    return (a.alias === this.props.location.pathname)
                });
                pages = pages[0]
                if (pages.alias !== 404) {
                    return fetch(process.env.REACT_APP_API_URL + '/jsonapi/node/' + type + '/' + pages.uuid + imgIncl)

                } else {
                    // redirect to route not found.
                    this.setState({
                        pageNotFound: true
                    })
                }

            }).then((resp) => {
                return resp.json()
            }).then((page) => {
                this._parsePage(page);
            })
            .catch((error) => {
                this.setState({
                    pageNotFound: true
                })
                console.log(error);
            });
    }



    _parsePage(page) {
        let content = page.data;
        let image = null;
        let related = null;
        if (image = page['mediaImage#uri{0}']) {
            image = JSON.parse(image.body).data;
        }

        if (related = page['related#uri{0}']) {
            related = JSON.parse(related.body).data;
        }

        if (content) {

            this.setState({
                content: content,
                pageTitle: content.attributes.title,
                image: image,
              id: content.attributes.id,
                related: related,
                loaded: true
            });
        } else {
            this.setState({
                pageNotFound: true
            })
        }
    }

    _parseCreatives = (creatives) => {
        if (creatives.data.length) {
            this.setState({
                creatives: creatives.data
            });
        } else {
            this.setState({
                pageNotFound: true
            })
        }
    }

    _renderCreatives = (id) => {
        const creatives = this.state.creatives;
        if (creatives && creatives.length) {
            return creatives.filter((item, key) => {
                if (item.id === id) {
                    let TagName = NodeTeaser;
                    let path = false;
                    let isExternal = false;

                    return (
                        <div className="col-12 mb-5" key={key}>
                            <TagName
                                key={item.id}
                                nid={item.attributes.drupal_internal__nid}
                                title={item.attributes.title}
                                path={path}
                                isExternal={isExternal}
                                text={(item.attributes.body) ? item.attributes.body.summary : ''}
                                image={(item.relationships.field_media_image) ? item.relationships.field_media_image.data : null}
                                hasDetail={(item.attributes.field_detail && path) ? item.attributes.field_detail : false}
                            />
                        </div>
                    )
                }

            });
        } else {
            return false;
        }
    }


    render = () => {
        return (
            <>
                <>
                    {this.state.pageNotFound !== true &&
                        <section className="page section">
                            <HelmetHead
                                pageTitle={this.state.pageTitle}
                                content={this.state.content}
                                image={this.state.image}
                                url={this.props.match.url}
                                project={this.props.project}
                            />
                            <Loader loaded={this.state.loaded}>
                                <PageHeader
                                    pageTitle={this.state.pageTitle}
                                    content={(this.state.content && this.state.content.attributes.body) ? this.state.content.attributes.body.value : ''}
                                    image={(this.state.image && this.state.image.relationships.field_media_image.data) ? this.state.image.relationships.field_media_image.data : null}
                                    imageStyle="large_landscape"
                                    meta={this.state.content ? this.state.content.attributes : ''}
                                />
                                {this.state.content &&
                                    <div className="container">

                                        <Node content={this.state.content}></Node>

                                        {this.state.related && <RelatedContent pageId={this.state.content.id} nodes={this.state.related} title={this.state.content.attributes.field_related_content_title} className="mb-5" />}

                                    </div>
                                }
                            </Loader>
                        </section>
                    }
                </>
                <>
                    {this.state.pageNotFound === true &&
                        <Redirect
                            to={{
                                pathname: "/404",
                                state: { from: this.props.location }
                            }}
                        />
                    }
                </>
            </>
        )

    }

}

export default PageBase;
