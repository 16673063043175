
import React from 'react';

export const renderImage = (image_style, image_meta) => {
  if (!image_style) {
    image_style = 'medium_landscape';
  }
  if (image_meta && image_meta.imageDerivatives) {
    return (
      <img src={image_meta.imageDerivatives.links[image_style].href}
        className="img-fluid"
        alt={image_meta.alt}
      />
    )
  }
}
